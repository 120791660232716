@font-face {
  font-family: "Benne";
  src: url("./fonts/Benne/Benne-Regular.ttf") format("truetype");
}

$theme-colors: (
  "primary": #16c6cc,
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
  background-color: white;
  margin: 0;
  font-family: 'Benne';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
