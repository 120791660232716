.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  height: 130px;
  background-color: #282c34;
}

.header-text {
  color: white;
  font-size: 50px;
}

.content {
  width: 50%;
}

.icon-button {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
